<template>

    <v-sheet width="100%" height="28vh" rounded outlined  >
      <v-container style="height: 100%;" >
        <v-row>
          <v-col cols="4" style="min-height: 100%;" >
            <v-card-title>Device Details</v-card-title>
            <ul
                style="list-style-type: none;height: 100%;display: flex;flex-direction: column;justify-content:space-around;margin:0%;padding: 0;" >
              <li class="detail-item">
                ID : <span>{{ devices[uid].code }}</span>
              </li>
              <li class="detail-item">
                MAC Address : <span>{{ devices[uid].mac_address }}</span>
              </li>
              <li class="detail-item">
                IMEI : <span>{{ devices[uid].code }}</span>
              </li>
              <li class="detail-item">
                Description : <span>{{ devices[uid].description }}</span>
              </li>
              <li class="detail-item">
                Home Fence : <span>{{geofences[devices[uid].geofence['uid']].properties.name }}</span>
              </li>
            </ul>
          </v-col>
          <v-col cols="4" >
            <v-list-item three-line>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Package
                </div>
                <v-list-item-title class="text-h5 mb-1">
                  {{ packages[devices[uid].package.uid].name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ packages[devices[uid].package.uid].description }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-avatar
                  tile
                  size="80"
                  :color="!Object.keys(packageImages).includes(devices[uid].package.uid)?'transparent':'grey'"
              >
                <v-icon v-if="!Object.keys(packageImages).includes(devices[uid].package.uid)" >
                  mdi-image-sync-outline
                </v-icon>
                <v-carousel v-if="Object.keys(packageImages).includes(devices[uid].package.uid)" :show-arrows="false" :hide-delimiters="true" continuous cycle>
                  <v-carousel-item v-for="img in packageImages[devices[uid].package.uid]" :key="img.uid">
                    <v-img :src="img.image" contain/>
                  </v-carousel-item>
                  <v-carousel-item  >

                  </v-carousel-item>
                </v-carousel>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar rounded>
                <v-icon :color="hazardLevels[ packages[devices[uid].package.uid].package_type.hazard.uid ].color">
                  mdi-biohazard
                </v-icon>
              </v-list-item-avatar>
              <v-spacer></v-spacer>
              <v-list-item-title>
                {{ hazardLevels[packages[devices[uid].package.uid].package_type.hazard.uid].name }}
              </v-list-item-title>
            </v-list-item>
          </v-col>
          <v-col cols="4" style="position: relative;" >
            <v-overlay absolute dark opacity="0.99"
                       style="max-width:100%;min-height: 28vh;transition:transform;transition-duration: 4s;font-size:2em;font-weight: bold;" class="trip-overlay" >
              Coming soon
            </v-overlay>
            <v-card-title>Trip & location</v-card-title>
            <ul
                style="list-style-type: none;height: 100%;display: flex;flex-direction: column;justify-content:space-around;margin:0%;padding: 0;" >
              <li class="detail-item">
                Trip ID
              </li>
              <li class="detail-item">
                Trip Name
              </li>
              <li class="detail-item">
                Trip Departure
              </li>
              <li class="detail-item">
                Trip Destination
              </li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

</template>

<script>

import {mapGetters} from "vuex";

export default {
  name:"DeviceDetailsOverlay",
  props:["uid"],
  computed:{
    ...mapGetters({
      devices:"getDevices",
      packages:"getPackages",
      hazardLevels: "getHazardLevels",
      packageImages:"getPackageImages",
      geofences: "getGeofence"
    })
  }
}

</script>

<style scoped>
.detail-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@keyframes changewidth {
  from {
    color:bisque;
    font-weight: 200;
  }
  to {
    color:#ccc;
    font-weight: 900;
  }
}

.trip-overlay {
  animation-duration: 4s;
  animation-name: changewidth;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

</style>