<template>
  <v-container>
    <v-card-title>
      GeoFences
      <v-spacer></v-spacer>
    </v-card-title>

    <v-list dense class="bg-transparent" >
      <v-list-item dense>
        <v-list-item-avatar>
          <v-icon>
            mdi-fence
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            Geofence(s)
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-menu
              right
              origin="center right"
              transition="scale-transition"
              offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small outlined v-bind="attrs" v-on="on" >
                <v-icon>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item @click="hideDeviceDetails" >
                <v-list-item-title>Show Fences</v-list-item-title>
              </v-list-item>
              <v-list-item to="/geofence/" >
                <v-list-item-title>Create Fences</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
      <v-list-item-group>
        <v-list-item v-for="fence in fences" :key="fence.properties.uid" @click="updateFocusGeoFence(fence.properties.uid)" class="bg-transparent">
          <v-list-item-content>
            <v-list-item-title>
              {{ fence.properties.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-chip small :color="fence.properties.is_active?'green':'red'" >
                {{ fence.properties.is_active ? "Activated":"Deactivated" }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon small  :to="'/geofence/'+fence.properties.uid">
              <v-icon small >
                mdi-feather
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>

  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "GeoFenceList",
  props: ['devices','fences'],
  methods: {
    ...mapActions([
        'updateFocusGeoFence',
        'toggleGeoFenceListView'
    ]),
  }
}
</script>

<style scoped>
.Fensecard {
  position: absolute;
  z-index: 999;
  width: 300px;
  margin-top: 10px;
  margin-left: calc(100% - 370px);
}
</style>