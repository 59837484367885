<template>
  <v-container class="bg-transparent">
    <v-card-title>
      Packages
      <v-spacer></v-spacer>
    </v-card-title>

    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header> Hazard Levels</v-expansion-panel-header>
        <v-expansion-panel-content class="p-0">

          <v-list dense class="p-0">
            <v-list-item
                v-for="hazard in hazardLevels" :key="hazard.uid"
                dense
                ripple
                :style="{'color':hazard.color}"
            >
              <v-list-item-content class="p-0">
                <v-list-item-title>{{ hazard.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon small>
                  <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>

          <v-btn small @click="()=>addHazardModel = !addHazardModel">
            <v-icon>mdi-plus</v-icon>
            Add Hazard Type
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>Package Types</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-btn small @click="()=>addPackageTypeDialog = !addPackageTypeDialog">
            <v-icon>mdi-plus</v-icon>
            Add Package Type
          </v-btn>
          <v-list dense v-if="packageTypes">
            <v-list-item
                v-for="pack in packageTypes" :key="pack.uid"
                dense
                ripple
                :style="{'color':pack.color}"
            >
              <v-list-item-content class="p-0">
                <v-list-item-title>{{ pack.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small>
                  <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider></v-divider>

    <v-card elevation="0" class="bg-transparent text-center">
      <v-card-actions>
        <v-btn rounded color="primary" outlined small @click="addNewPackageDialog=true">
          <v-icon>
            mdi-plus
          </v-icon>
          Add Package
        </v-btn>
      </v-card-actions>
      <v-card-title class="text-warning text-center" v-if="packagesList === {}">
        No Packages Found
      </v-card-title>
      <v-card-text v-if="packagesList === {}">
        Add new Package
      </v-card-text>

      <v-card-text>
        <v-list three-line class="bg-transparent" v-if="packagesList !== {}">
          <v-list-item v-for="p in packagesList" :key="p.uid">
            <v-list-item-avatar>
              <v-icon :color="hazardLevels[p.package_type.hazard.uid].color" >
                mdi-biohazard
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-left" >{{ p.name }}</v-list-item-title>
              <v-list-item-subtitle class="text-left" >{{ p.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>

    <!-- Add Hazard Type Dialog -->
    <v-dialog v-model="addHazardModel" max-width="450px">
      <v-card flat>
        <v-card-title>New Hazard Type</v-card-title>
        <v-card-text>
          <v-text-field v-model="newHazardForm.name" placeholder="Name"></v-text-field>
          <v-text-field v-model="newHazardForm.description" placeholder="description"></v-text-field>
          <v-color-picker v-model="newHazardForm.color" @change="(c)=>newHazardForm.color=c"></v-color-picker>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn @click="submitNewHazard">
              Save
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add Package Type Dialog -->
    <v-dialog v-model="addPackageTypeDialog" max-width="450px">
      <v-card flat>
        <v-card-title>
          Add Package Type
          <v-spacer></v-spacer>
          <v-btn icon color="danger" @click="()=>addPackageTypeDialog =!addPackageTypeDialog">
            <v-icon color="danger">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="newPackageTypeForm" lazy-validation @submit.prevent="submitNewPackageType">
            <v-select
                v-model="newPackageTypeForm.hazard"
                :items="Object.values(hazardLevels)"
                item-text="name"
                item-value="uid"
                label="Select hazard type"
                require
                error-messages="Hazard type is required"
                :error="newPackageTypeForm.hazard===null"
                :disabled="Object.values(hazardLevels).length < 1"
            ></v-select>
            <v-text-field v-model="newPackageTypeForm.name" placeholder="Name *" type="text" required
                          error-messages="Field is required"></v-text-field>
            <v-text-field v-model="newPackageTypeForm.description" placeholder="Description" type="text"></v-text-field>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn type="submit" color="primary">
                Submit
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="warning" type="reset" @click="resetNewPackageTypeForm">
                Reset
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Add New Package Dialog -->
    <v-dialog v-model="addNewPackageDialog" max-width="450px">
      <v-card flat>
        <v-card-title>
          <v-icon class="mr-2">
            mdi-package
          </v-icon>
          Add New Package
          <v-spacer></v-spacer>
          <v-btn icon @click="addNewPackageDialog=false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form lazy-validation @submit.prevent="submitNewPackage">
            <v-row class="p-0 mb-0">
              <v-col cols="11">
                <v-file-input
                    flat
                    filled
                    multiple
                    required
                    label="Add Package Images"
                    prepend-icon="mdi-image-plus"
                    v-model="newPackageImages"
                    @change="handleFileChange"
                    class="p-0 my-0"
                ></v-file-input>
              </v-col>
              <v-col cols="1">
                <div class="container">
                  <div
                      style="height: 100%; display: flex;flex-direction: column;align-items: center;justify-content: center; align-content: center;">
                    <v-btn type="button" icon @click="()=>showImagePreview =!showImagePreview">
                      <v-icon v-if="!showImagePreview">
                        mdi-eye-outline
                      </v-icon>
                      <v-icon v-if="showImagePreview">
                        mdi-eye-off-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-sheet class="p-0" v-if="newPackageForm.packageImages.length > 0 && showImagePreview">
              <v-carousel
                  :continuous="false"
                  :cycle="true"
                  :show-arrows="false"
                  hide-delimiter-background
                  delimiter-icon="mdi-circle"
                  height="250"
                  class="mt-5"
              >
                <v-carousel-item
                    v-for="(slide, i) in newPackageForm.packageImages"
                    :key="i"
                >
                  <v-sheet
                      color="black accent-2"
                      height="100%"
                      tile
                  >
                    <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                    >
                      <div class="text-h2">
                        <v-img :src="slide.url" cover/>
                      </div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </v-sheet>
            <v-divider v-if="newPackageForm.packageImages.length > 0 && showImagePreview"></v-divider>
            <v-select
                v-model="newPackageForm.package_type"
                :items="Object.values(packageTypes)"
                item-text="name"
                item-value="uid"
                label="Select Package Type"
                required
                :disabled="Object.values(packageTypes).length < 1"
                prepend-icon="mdi-package-outlined"
                class="my-0 py-0"
            ></v-select>
            <v-text-field v-model="newPackageForm.name" placeholder="Package Name" required
                          prepend-icon="mdi-package"></v-text-field>
            <v-text-field v-model="newPackageForm.description" placeholder="Package Description" required
                          prepend-icon="mdi-text"></v-text-field>
            <v-expansion-panels
                class="p-0 m-0"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="pl-2 pr-2 m-0">
                  <b class="pt-1">
                    Package Attributes
                  </b>
                  <template v-slot:actions>
                    <v-icon>
                      mdi-format-list-bulleted-type
                    </v-icon>
                  </template>
                </v-expansion-panel-header>

                <v-expansion-panel-content class="p-0 m-0">
                  <v-row class="p-0">
                    <v-col cols="5" class="py-0 my-0">
                      <v-text-field v-model="packageAttributes.name" placeholder="Attribute"></v-text-field>
                    </v-col>
                    <v-col cols="5" class="py-0 my-0">
                      <v-text-field v-model="packageAttributes.value" placeholder="Value"></v-text-field>
                    </v-col>
                    <v-col cols="2" class="py-0 my-0">
                      <div
                          style="justify-content: center;align-items: center;height: 100%;display: flex;flex-direction: column;">
                        <v-btn small icon @click="addPackageAttribute">
                          <v-icon>
                            mdi-playlist-plus
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col cols="12">
                      <v-list dense v-if="Object.keys(newPackageForm.attributes).length > 0" two-line>
                        <v-list-item dense v-for="(attr,i) in Object.values(newPackageForm.attributes)" :key="i">
                          <v-list-item-avatar>
                            <v-btn icon @click="()=>{
                                packageAttributes.name = attr.name;
                                packageAttributes.value = attr.value;
                              }">
                              <v-icon>
                                mdi-playlist-edit
                              </v-icon>
                            </v-btn>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title v-text="attr.name"/>
                            <v-list-item-subtitle v-text="attr.value"/>
                          </v-list-item-content>

                          <v-list-item-action>
                            <v-btn @click="deleteAttribute(attr.id)">
                              <v-icon>mdi-delete-variant</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions>
              <v-btn color="primary" type="submit">
                Submit
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="warning" type="reset">
                Reset
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>

      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
const FormData = require('form-data');

import {mapActions, mapGetters} from "vuex";

export default {
  name: "PackageList",
  data: () => ({
    addHazardModel: false,
    addPackageTypeDialog: false,
    addNewPackageDialog: false,
    newHazardForm: {
      name: null,
      description: null,
      color: "#fff"
    },
    newPackageTypeForm: {
      hazard: null,
      name: null,
      description: null
    },
    newPackageImages:[],
    showImagePreview: false,
    newPackageForm: {
      packageImages: [],
      name: null,
      description: null,
      attributes: {},
      package_type: null,
      date_added: new Date().getDate()
    },
    packageAttributes: {
      name: null,
      value: null
    }
  }),
  methods: {
    ...mapActions(["addHazardLevels", "addNewPackageTypes", 'setSnackbarMessage', 'setSnackbarColor', 'showSnackbar','addNewPackage','addNewPackageImage']),
    async submitNewHazard() {
      await this.addHazardLevels(this.newHazardForm).then((r) => {
        if (r) {
          this.setSnackbarColor("green accent-2");
          this.setSnackbarMessage("New Hazard Created successfully");
          this.showSnackbar();
          this.addHazardModel = false;
        } else {
          this.setSnackbarColor("red accent-2");
          this.setSnackbarMessage("Failed to Create Hazard");
          this.showSnackbar();
        }
      })
    },
    async submitNewPackageType() {
      this.addNewPackageTypes(this.newPackageTypeForm).then((r) => {
        if (r) {
          this.setSnackbarColor("green accent-2");
          this.setSnackbarMessage("Package Type Create successfully");
          this.showSnackbar();
          this.addPackageTypeDialog = false;
        } else {
          this.setSnackbarColor("red accent-2");
          this.setSnackbarMessage("Failed To Create Package Type");
          this.showSnackbar();
        }
      })
    },
    resetNewPackageTypeForm() {
      this.newPackageTypeForm.name = null;
      this.newPackageTypeForm.hazard = null;
      this.newPackageTypeForm.description = null;
    },
    resetNewPackageForm (){
      this.newPackageForm.name = null
      this.newPackageForm.package_type =null
      this.newPackageForm.attributes = null
      this.newPackageForm.description = null
      this.newPackageForm.packageImages = []
    },
    async addPackageAttribute() {
      if (this.packageAttributes.name !== null || this.packageAttributes.value !== null) {
        const name = this.packageAttributes.name;
        const value = this.packageAttributes.value;
        const d = new Date().getMilliseconds();
        let existing = Object.values(this.newPackageForm.attributes).find((i) => i.name.toLowerCase() === name.toLowerCase());
        if (existing) {
          this.newPackageForm.attributes[existing.id] = {name, value, id: existing.id};
        } else {
          this.newPackageForm.attributes[d] = {name, value, id: d};
        }
        this.packageAttributes.name = null;
        this.packageAttributes.value = null;
      } else {
        await this.setSnackbarColor("red accent-2");
        this.setSnackbarMessage("Attributes must have a name and value");
        await this.showSnackbar();
      }
    },
    deleteAttribute(id) {
      let data = this.newPackageForm.attributes
      delete data[id];
      this.newPackageForm.attributes = data
    },
    handleFileChange(event) {
      const files = event;
      // const maxSize = 1024 * 1024; // 1MB
      if (!files.length) {
        return;
      }
      for (const file of files) {
        this.newPackageForm.packageImages.push({
          name: file.name,
          url: URL.createObjectURL(file),
          file
        });
      }
    },
    removeImage(image) {
      this.newPackageForm.packageImages = this.newPackageForm.packageImages.filter((img) => img !== image);
    },
    getContentTypeFromFileName(fileName) {
      const extension = fileName.split('.').pop().toLowerCase();
      switch (extension) {
          case 'jpg':
          case 'jpeg':
              return 'image/jpeg';
          case 'png':
              return 'image/png';
          case 'gif':
              return 'image/gif';
          // Add more cases for other image types if needed
          default:
              return 'application/octet-stream';
      }
    },

    async fetchBlob(url) {
      const response = await fetch(url);
      return await response.blob();
    },
    blobToArrayBuffer(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    },

    async submitNewPackage() {
      const newPackage = this.newPackageForm;
      //validate and check for nulls
      this.setSnackbarColor("red accent-2");
      let doSubmit = true;

      newPackage.packageImages.forEach(async (image)=> {
        try {
          
            const blob = await this.fetchBlob(image.url);
            const arrayBuffer = await this.blobToArrayBuffer(blob);
            
            await this.addNewPackageImage({package:"6804b568-bed1-400a-8690-f248f9217854","image":arrayBuffer}).then((r)=>{
              console.log(r)
            })
          
            console.log('Uploaded:', image.name);
        } catch (error) {
          console.error('Error uploading images:', error);
        }
      })

      Object.keys(newPackage).forEach(k=> {

        if(newPackage.packageImages.length<1){
          this.setSnackbarMessage("Please images of your package.");
          this.showSnackbar();
          doSubmit=false;
          return;
        }
        if(k==="package_type" && newPackage[k]===null||newPackage[k]===""){
          this.setSnackbarMessage("Please Select a package type.");
          this.showSnackbar();
          doSubmit=false;
          return;
        }
        if(k==="attributes" && newPackage[k]==null||newPackage[k]===""){
          this.setSnackbarMessage("Please add a package attribute");
          this.showSnackbar();
          doSubmit=false;
          return;
        }
        if(k==="description" && newPackage[k]==null||newPackage[k]===""){
          this.setSnackbarMessage("Please add a package description");
          this.showSnackbar();
          doSubmit=false;
          return;
        }

        if(k==="name" && newPackage[k]==null||newPackage[k]===""){
          this.setSnackbarMessage("Please add a package description");
          this.showSnackbar();
          doSubmit=false;
          return;
        }
      })
      if(doSubmit){
        // Create Form Data
        let formData = new FormData();
        // Append other fields
        formData.append('name', newPackage.name);
        formData.append('description', newPackage.description);
        formData.append('package_type', newPackage.package_type);
        formData.append('date_added', newPackage.date_added.toString());

        // Convert "attributes" to JSON string and append it
        const attributesValue = {};
         Object.values(newPackage.attributes).forEach(obj => {
          attributesValue[obj.name] = obj.value;
        });
        formData.append('attributes', JSON.stringify(attributesValue));

        this.addNewPackage(formData).then(r=>{
          if (r) {
            this.setSnackbarColor("green accent-2");
            this.setSnackbarMessage("New Package Created successfully");
            this.showSnackbar();
            this.addHazardModel = false;
          } else {
            this.setSnackbarColor("red accent-2");
            this.setSnackbarMessage("Failed to Create Package");
            this.showSnackbar();
          }
          this.resetNewPackageForm();
        })
      }

    }
  },
  computed: {
    ...mapGetters({
      packagesList: "getPackages",
      packageTypes: "getPackageTypes",
      hazardLevels: "getHazardLevels"
    })
  }
}
</script>

<style>

</style>