<template>
  <v-app id="inspire">

    <v-main>

      <v-snackbar
          v-model="snackbar.visible"
          :timeout="snackbar.timeout"
          absolute
          style="z-index: 9999;"
          top
          right
          :color="snackbar.color"
      >
        {{ snackbar.message }}

        <template v-slot:action="{ attrs }">
          <v-btn
              v-bind="attrs"
              @click="hideSnackbar()"
              icon
              small
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <div class="sideBars bg-dark" >

        <v-row no-gutters >
          <v-col cols="2" >

            <v-list dense class="bg-transparent" >

              <v-list-item v-for="(k,v) in NavMenuOptions" :key="v" @click="()=>switchWindow(k.id)" >
                <v-list-item-icon>
                  <i :class="`${k.icon} mdi-lg`"></i>
                </v-list-item-icon>
                <v-list-item-title> {{k.title}} </v-list-item-title>
              </v-list-item>

            </v-list>

          </v-col>
          <v-col cols="2" v-if="isPane2Open===true" style="position: relative;" >
            <DevicesMenu :broadcast="markers" @focus="showDeviceDetails" v-if="isDeviceMenuOpen"/>
            <DeviceReport v-if="isReportOpen" @reportViewError="triggerSnackBar"/>

            <OptionsMenu v-if="isOptionsMenuOpen" @hideDetails="hideDeviceDetails" />
            <GeoFenceList v-if="geofenceListViewState" :devices="devices" :fences="loadedFences"/>

            <StaffMenu v-if="isStaffMenuOpen" />
            <PackageList v-if="isPackagesListOpen" />

            <div style="position: absolute;right: -2.7em;top: 49%;z-index: 9999;">
            <v-btn fab small @click="()=>isPane2Open = false" >
              <v-icon>
                mdi-chevron-left
              </v-icon>
            </v-btn>
            </div>

          </v-col>
          <v-col :cols="isPane2Open?8:10" style="position: relative;" >
              <l-map :zoom="zoom" :center="home" class="map" ref="map" :markerZoomAnimation="true" :zoomAnimation="true">
                <l-tile-layer :url="mapUrl" :attribution="mapAttribution" layer-type="base"></l-tile-layer>
                <l-marker :lat-lng="markers.home"></l-marker>
                <l-marker :lat-lng="v.last_location" v-for="(v,k) in devices" :key="k" @click="showDeviceDetails(k)"></l-marker>
                <l-polygon v-for="poly in loadedFences" :lat-lngs="poly.geometry.coordinates[0]" :key="poly.properties.uid" color="red" fill-color="white" ></l-polygon>
              </l-map>

            <v-container class="details_stage" v-if="focusMarker.is_open" >
              <DeviceDetailsOverlay :uid="focusMarker.uid"/>
            </v-container>

            <DeviceDetails class="device_gps_card" v-if="focusMarker.is_open" :uid="focusMarker.uid"
                           @closeDeviceDetails="focusMarker.is_open=false"/>

          </v-col>
        </v-row>

      </div>


    </v-main>
  </v-app>
</template>

<script>
import {LMap, LTileLayer, LMarker, LPolygon} from 'vue2-leaflet';
import {mapActions, mapGetters, mapMutations} from "vuex";
import DevicesMenu from "@/components/DevicesMenu";
import DeviceDetails from "@/components/DeviceDetails";
import DeviceReport from "@/components/DeviceReport";
import OptionsMenu from "@/components/OptionsMenu";
import GeoFenceList from "@/components/GeoFenceList";

import {latLng} from "leaflet";
import StaffMenu from "@/components/StaffMenu";
import PackageList from "@/components/PackageList.vue";
import DeviceDetailsOverlay from "@/components/OverlayCards/DeviceDetailsOverlay.vue";

export default {
  components: {
    DeviceDetailsOverlay,
    PackageList,
    StaffMenu,
    LMap,
    LTileLayer,
    LMarker,
    DevicesMenu,
    DeviceDetails,
    DeviceReport,
    LPolygon,
    OptionsMenu,
    GeoFenceList
  },
  data() {
    return {
      drawer: true,
      NavMenuOptions:[
        {
          id:1,
          title: "Devices",
          icon:"mdi mdi-chip"
        },
        {
          id:2,
          title: "Packages",
          icon:"mdi mdi-package-variant-closed"
        },
        {
          id:3,
          title: "Geo Fences",
          icon:"mdi mdi-map"
        },
        {
          id:4,
          title: "Reports",
          icon:"mdi mdi-file-document-outline"
        },
        {
          id:5,
          title: "Team",
          icon:"mdi mdi-account-multiple"
        },
      ],
      mapZoom: 10,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10,
      home: [-26.8748, 26.6532],
      focusMarkerID: null,
      views: {
        devices: false
      },
      markers: {
        home: latLng(-26.8748, 26.6532)
      },
      geoFences: {
        is_fencing: false,
        circles: {
          'C1': {
            center: [47.413220, -1.0482],
            radius: 4500,
            color: 'red'
          }
        },
        polygons: {
          "1": {}
        }
      },
      focusMarker: {
        is_open: false,
        uid: null,
      },
      isPane2Open:false,
    };
  },
  methods: {
    ...mapActions(['connect_User', 'LoadDevices', 'mutateBroadcast', 'loadPackages', 'loadHazards', 'toggleDeviceMenu',
      'setFocusDeviceUID', 'toggleReportWindow', 'toggleOptionsMenu', 'setSnackbarMessage', 'setSnackbarColor',
      'showSnackbar', 'hideSnackbar','loadGeoFences','Logout','togglePackageMenu','toggleStaffMenu',
      'toggleGeoFenceListView','loadPackageTypes']),
    ...mapMutations(['updateDeviceLocation','changeDeviceMenu']),
    updateMap() {
      // this.markers.home = latLng(-26.8748, 26.6532)
    },
    switchWindow(id){
      switch (id){
        case  1:
          this.toggleDeviceMenu(true);
              break;
        case  2:
          this.togglePackageMenu(true);
          break;
        case 3:
          this.toggleGeoFenceListView(true);
          break;
        case  4:
          this.toggleReportWindow(true);
          break;
        case  5:
          this.toggleStaffMenu(true);
          break;
      }
      this.isPane2Open = true;
    },
    showDeviceDetails(uid) {
      this.focusMarkerID = uid
      this.focusMarker.is_open = true
      this.focusMarker.uid = uid

      this.home = this.devices[uid].last_location
      this.zoom = 20

      this.setFocusDeviceUID(uid)
    },
    hideDeviceDetails(){
      this.changeDeviceMenu(false)
      this.focusMarker.is_open=false
    },
    recenter(coords){
      this.home = [coords.lat, coords.lng]
    },
    triggerSnackBar(v) {
      this.setSnackbarColor(v.color)
      this.setSnackbarMessage(v.message)
      this.showSnackbar()
    },
    async doLogout(){
      this.Logout()
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters({
      mapAttribution: 'getMapAttribution',
      mapUrl: 'getMapUrl',
      user: 'getUserData',
      api: 'getApi',
      broadcast: 'getBroadcast',
      socket: 'getUserConnection',
      devices: 'getDevices',
      isDeviceMenuOpen: 'getDeviceMenuState',
      isReportOpen: 'getReportWindowState',
      isOptionsMenuOpen: 'getOptionsMenuState',
      isPackagesListOpen: 'getPackageListState',
      geofenceListViewState: 'getGeoFenceListViewState',
      isStaffMenuOpen: 'getStaffMenuState',
      loadedFences: 'getGeofence',
      snackbar: 'getSnackbar',
      focusGeofenceUID: 'getFocusGeofenceUID'
    })
  },
  created() {
    this.connect_User()
    this.loadPackages()
    this.loadPackageTypes()
    this.LoadDevices()
    this.loadHazards()
    this.loadGeoFences()

    this.socket.on('message',(v)=>{
      const message = v
      const key = message['device']

      const value = latLng(message.lon, message.lat)
      const broadcast = {}
      broadcast[key] = value
      broadcast.speed = message.speed
      this.markers[key] = value
      if(key === this.focusMarkerID){
        this.recenter(value)
      }
      this.updateDeviceLocation({key, location: value})
      // this.mutateBroadcast(broadcast)

      this.updateMap()
      // !this.geoFences.is_fensing ? this.updateMap() : null
    })

  },
  mounted() {
    this.drawingToolsStatus ? this.InitDrawingTools() : null
  },
  watch: {
    focusGeofenceUID: {
      deep: true,
      immediate: true,
      handler(v){
        if(v){
          const coords = latLng(this.loadedFences[v].geometry.coordinates[0][0])
          this.recenter(coords)
        }

      }
    }
  }
}
</script>

<style scoped>
.map {
  height: 100vh;
  width: 100vw;
}
.sideBars {
  width: 100vw;
  height: 100vh;
  display: block;
  position: fixed;
  z-index: 999;
}
.details_stage {
  position: absolute;
  width:90%;
  height: 30vh;
  z-index: 9999;
  bottom: 5vh;
  right:5%;
  padding: 0.2em;
}
.device_gps_card{
  z-index: 9999;
  position: absolute;
  top: 3vh;
  right: 3vw;
}
</style>